body {
  font-family: 'Libre Baskerville', serif;
}

/* Możemy również zastosować font do konkretnych elementów, jeśli chcemy */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Libre Baskerville', serif;
  font-weight: 700; /* Używamy pogrubionej wersji dla nagłówków */
}

/* Możemy dostosować inne style globalne według potrzeb */
.btn {
  font-family: 'Libre Baskerville', serif;
}

/* Możemy również użyć kursywy dla pewnych elementów */
em, i {
  font-family: 'Libre Baskerville', serif;
  font-style: italic;
}
